import "bootstrap";
import $ from 'jquery'

$('body').on('click', function (){
    $('.calendar-day.available').removeClass('active');
    $('#select-time-block').removeClass('active');
});
$(document).on('click', '.calendar-day.available', function (e){
    e.preventDefault()
    e.stopPropagation()
    $('.calendar-day.available').removeClass('active');
    $(this).addClass('active');
    var left = $(this).position().left + $(this).width() / 2 + 10;
    var top = $(this).position().top + $('.calendar-days').position().top + $(this).height() / 2 + 6;
    if (document.body.clientWidth < 1000){
        if (left > $('#calendar').width() - $('#select-time-block').width()-30){
            left = $('#calendar').width() - $('#select-time-block').width()-30
        }
        if (top > $('#calendar').height() - $('#select-time-block').height()-80){
            top = $('#calendar').height() - $('#select-time-block').height()-80
        }
    }
    $('#select-time-block').css({"left": left, "top": top});
    $('#select-time-block').addClass('active');
});

$('.form-checkbox input').change(function (){
    if ($(this).prop('checked')){
        $(this).parents('.form-checkbox').addClass('checked');
    }else{
        $(this).parents('.form-checkbox').removeClass('checked');
    }
});

$('.form-checkbox input').change(function (){
    if ($(this).prop('checked')){
        $(this).parents('.form-checkbox').addClass('checked');
    }else{
        $(this).parents('.form-checkbox').removeClass('checked');
    }
});
$('.calendar-format').on('click', function (){
    $('.calendar-format').removeClass('active')
    $(this).addClass('active')
});


